import { Text, Container, SimpleGrid, createStyles, Title, Flex, Group, Button, Box, ThemeIcon } from "@mantine/core"
import React from "react"
import { Link } from "react-scroll"
import { useMediaQuery } from "@mantine/hooks"
import {
  IconBarbell,
  IconFirstAidKit,
  IconHeartbeat,
  IconJumpRope,
  IconNotebook,
  IconPhysotherapist,
  IconSchool,
  IconZoomExclamation,
} from "@tabler/icons-react"
import { AppTitle } from "../AppTitle"

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: `calc(${theme.spacing.xl} * 4)`,
    paddingBottom: `calc(${theme.spacing.xl} * 4)`,

    [theme.fn.smallerThan("md")]: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    },

    [theme.fn.smallerThan("xs")]: {
      paddingTop: `calc(${theme.spacing.xl} * 1.5)`,
      paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },

    position: "relative",
    backgroundImage: "url(assets/features.png)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },

  container: {
    alignItems: "flex-start",
    gap: 24,
  },

  buttonBox: {
    marginTop: 48,

    [theme.fn.smallerThan(900)]: {
      marginTop: 32,
    },
  },

  text: {
    fontSize: 18,

    [theme.fn.smallerThan(768)]: {
      fontSize: 16,
    },
  },
}))

export function YouLearned(): React.JSX.Element {
  const { classes, theme } = useStyles()
  const md = useMediaQuery(`(max-width: 768px)`)
  const xs = useMediaQuery(`(max-width: 576px)`)

  const offset = md ? -48 : xs ? -36 : -72
  return (
    <div className={classes.wrapper}>
      <Container size={1320}>
        <AppTitle
          preTitle="В процессе обучения"
          accent="вы научитесь"
        />

        <SimpleGrid
          verticalSpacing={48}
          spacing={48}
          cols={2}
          breakpoints={[
            { maxWidth: "sm", cols: 1, spacing: 24, verticalSpacing: 24 },
          ]}
        >
          <Flex className={classes.container}>
            <ThemeIcon variant="light" radius={4} size="xl">
              <IconNotebook/>
            </ThemeIcon>
            <Text className={classes.text}>
              Составлять программу тренировок для своих клиентов с учетом индивидуальных особенностей организма
            </Text>
          </Flex>
          <Flex className={classes.container}>
            <ThemeIcon variant="light" radius={4} size="xl">
              <IconBarbell/>
            </ThemeIcon>
            <Text className={classes.text}>
              Проводить регулярное и периодическое фитнес-тестирование
            </Text>
          </Flex>
          <Flex className={classes.container}>
            <ThemeIcon variant="light" radius={4} size="xl">
              <IconJumpRope/>
            </ThemeIcon>
            <Text className={classes.text}>
              Оценивать интенсивность аэробного и анаэробного тренинга. И определять уровень индивидуального восприятия
              физической нагрузки
            </Text>
          </Flex>
          <Flex className={classes.container}>
            <ThemeIcon variant="light" radius={4} size="xl">
              <IconSchool/>
            </ThemeIcon>
            <Text className={classes.text}>
              Изучите фундаментальные науки: анатомия, физиология, биомеханика, необходимые для работы персонального
              фитнес-тренера
            </Text>
          </Flex>
          <Flex className={classes.container}>
            <ThemeIcon variant="light" radius={4} size="xl">
              <IconZoomExclamation/>
            </ThemeIcon>
            <Text className={classes.text}>
              Выявлять и корректировать ошибки в технике выполнения физических упражнений
            </Text>
          </Flex>
          <Flex className={classes.container}>
            <ThemeIcon variant="light" radius={4} size="xl">
              <IconPhysotherapist/>
            </ThemeIcon>
            <Text className={classes.text}>
              Разберете на практике технику выполнения более 75+ упражнений на все мышечные группы
            </Text>
          </Flex>
          <Flex className={classes.container}>
            <ThemeIcon variant="light" radius={4} size="xl">
              <IconFirstAidKit/>
            </ThemeIcon>
            <Text className={classes.text}>
              Основам оказания первой медицинской помощи
            </Text>
          </Flex>
          <Flex className={classes.container}>
            <ThemeIcon variant="light" radius={4} size="xl">
              <IconHeartbeat/>
            </ThemeIcon>
            <Text className={classes.text}>
              Изучите причины и риски возникновения травм при занятиях фитнесом и методы их предотвращения
            </Text>
          </Flex>
        </SimpleGrid>


        <Group className={classes.buttonBox} position="center">
          <Flex align="center" justify="center" direction="column">
            <Button
              variant="gradient"
              gradient={{ from: "blue", to: "cyan", deg: 105 }}
              size={md ? "md" : "lg"}
              radius={4}
              to={"prices"}
              smooth={true}
              duration={1000}
              component={Link}
              offset={offset}
            >
              Стать студентом
            </Button>
          </Flex>
        </Group>
      </Container>

    </div>
  )
}
