import {
  createStyles,
  Title,
  Container,
  Text,
  Flex,
  Box,
  SimpleGrid,
} from "@mantine/core"
import React from "react"
import { Image } from "../../shared/ui"
import { AppTitle } from "../AppTitle"

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",
    backgroundImage: "url(assets/features.png)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",

    paddingTop: `calc(${theme.spacing.xl} * 6)`,
    paddingBottom: `calc(${theme.spacing.xl} * 4)`,

    [theme.fn.smallerThan("md")]: {
      paddingTop: `calc(${theme.spacing.xl} * 3)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    },

    [theme.fn.smallerThan("xs")]: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },
  },

  element: {
    zIndex: 1,
    position: "relative",
    marginBottom: 15,
    marginTop: 40,
    padding: 30,

    "&::after": {
      content: "''",
      background: theme.fn.rgba(theme.colors.blue[6], 0.1),
      height: "100%",
      left: 0,
      top: 0,
      position: "absolute",
      transform: "skewY(-5deg)",
      width: "100%",
      zIndex: -1,
      borderRadius: 4,
    },
  },

  elementIcon: {
    alignItems: "center",
    background: theme.white,
    boxShadow: `0 -10px 50px 0 ${theme.colors.blue[1]}`,
    display: "flex",
    height: 120,
    justifyContent: "center",
    marginTop: -70,
    width: 120,
    borderRadius: 4,
  },

  elementContent: {
    fontSize: 18,
    lineHeight: 1.5,
    paddingTop: 30,
    paddingBottom: 15,

    [theme.fn.smallerThan(768)]: {
      fontSize: 16,
    },
  },
}))

export function Features(): React.JSX.Element {
  const { classes } = useStyles()

  return (
    <div className={classes.wrapper}>
      <Container size={1320}>
        <AppTitle
          preTitle="Это профессиональное обучение"
          accent="для тех, кто"
        />

        <SimpleGrid
          cols={3}
          spacing={24}
          verticalSpacing={24}
          breakpoints={[
            { maxWidth: "lg", cols: 3 },
            { maxWidth: "md", cols: 2 },
            { maxWidth: "xs", cols: 1 },
          ]}
        >
          <Box className={classes.element}>
            <Box className={classes.elementIcon}>
              <Image src="assets/features/money-bag.png" objectFit="contain"/>
            </Box>
            <Flex className={classes.elementContent}>
              Задумывается о том, чтобы получить новую профессию и зарабатывать на своем любимом деле.
            </Flex>
          </Box>
          <Box className={classes.element}>
            <Box className={classes.elementIcon}>
              <Image src="assets/features/physical-wellbeing.png" objectFit="contain"/>
            </Box>
            <Flex className={classes.elementContent}>
              Является специалистом в сфере фитнеса и хочет повысить свою квалификацию в соответствии с государственным
              профстандартом.
            </Flex>
          </Box>
          <Box className={classes.element}>
            <Box className={classes.elementIcon}>
              <Image src="assets/features/genetics.png" objectFit="contain"/>
            </Box>
            <Flex className={classes.elementContent}>
              Придерживается позиций научного подхода и хочет изучать методику фитнес-тренировок с научной точки зрения.
            </Flex>
          </Box>
        </SimpleGrid>
      </Container>
    </div>
  )
}
