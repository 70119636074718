import React from "react"
import {
  About,
  Docs,
  Faq,
  Features,
  Footer,
  Form,
  FourSteps,
  Gpt,
  Hero,
  Licence,
  Menu,
  Prices,
  Program,
  ReviewCarusel,
  Subjects,
  Team,
  TestDrive,
  YouLearned,
  AboutCourse,
} from "../components"
import LightboxView from "../components/lightboxView"

export function MainPage(): React.JSX.Element {
  return (
    <>
      <Menu/>
      <Hero/>
      <About/>
      <Features/>
      <AboutCourse/>
      <Team/>
      <Program/>
      <YouLearned/>
      <Docs/>
      <Subjects/>
      <Gpt/>
      <Licence/>
      <ReviewCarusel/>
      <Prices/>
      <TestDrive/>
      <LightboxView/>
      <FourSteps/>
      <Faq/>
      <Form/>
      <Footer/>
    </>
  )
}
