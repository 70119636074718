import { Carousel } from "@mantine/carousel"
import { Container, Title, createStyles, Text, Box } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { ReviewVideoCard } from "./ReviewVideoCard"
import { caruselItems } from "./config"
import React from "react"
import { IconArrowLeft, IconArrowRight } from "@tabler/icons-react"
import { AppTitle } from "../AppTitle"

// ----------------------------------------------------------------------

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: `calc(${theme.spacing.xl} * 4)`,
    paddingBottom: `calc(${theme.spacing.xl} * 4)`,

    [theme.fn.smallerThan("md")]: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    },

    [theme.fn.smallerThan("xs")]: {
      paddingTop: `calc(${theme.spacing.xl} * 1.5)`,
      paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
      paddingRight: 0,
      paddingLeft: "1rem",
    },
  },

  carusel: {
    ".mantine-Carousel-controls": {
      ".mantine-Carousel-control": {
        borderRadius: 4,
        boxShadow: "none",
        border: "none",
        color: theme.colors.blue[6],

        backgroundColor: theme.fn.rgba(theme.colors.blue[0], 0.5),

        "&:hover": {
          backgroundColor: theme.fn.rgba(theme.colors.blue[0], 0.8),
        },
      },
    },
  },
}))

export function ReviewCarusel(): React.JSX.Element {
  const { classes, theme } = useStyles()
  const matches = useMediaQuery(`(min-width: ${theme.breakpoints.xs})`)

  return (
    <div className={classes.wrapper}>
      <Container size={1320}>
        <AppTitle
          preTitle="Отзывы о"
          accent="BodyCoach"
        />

        <Carousel
          className={classes.carusel}
          withControls={matches}
          height={426}
          slideSize="25%"
          slideGap="xl"
          loop
          align="start"
          controlSize={40}
          breakpoints={[ { maxWidth: "lg", slideSize: "33.333%", slideGap: "xs" } ]}
          nextControlIcon={<IconArrowRight/>}
          previousControlIcon={<IconArrowLeft/>}
        >
          {caruselItems.map((item) => (
            <Carousel.Slide key={item.id}>
              <ReviewVideoCard {...item} />
            </Carousel.Slide>
          ))}
        </Carousel>
      </Container>
    </div>
  )
}
