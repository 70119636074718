import { Timeline, Container, Center, createStyles, Flex, Box, Button } from "@mantine/core"
import {
  IconLungs,
  IconGavel,
  IconMicroscope,
  IconFirstAidKit,
  IconReportMoney,
  IconVectorTriangle,
  IconHeartbeat,
  IconYoga,
  IconStretching,
  IconShieldHalfFilled,
} from "@tabler/icons-react"
import React from "react"
import { Image } from "../../shared/ui"
import { useMediaQuery } from "@mantine/hooks"

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",
    backgroundImage: "url(assets/team.png)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    zIndex: 0,

    paddingTop: `calc(${theme.spacing.xl} * 4)`,
    paddingBottom: `calc(${theme.spacing.xl} * 4)`,

    [theme.fn.smallerThan("md")]: {
      paddingTop: `calc(${theme.spacing.xl} * 1)`,
      paddingBottom: `calc(${theme.spacing.xl} * 3)`,
    },

    [theme.fn.smallerThan("xs")]: {
      paddingTop: `calc(${theme.spacing.xl} * 1)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    },
  },

  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 64,

    [theme.fn.smallerThan("md")]: {
      marginBottom: 48,
    },

    [theme.fn.smallerThan("xs")]: {
      marginBottom: 24,
    },
  },

  titleOuter: {
    fontSize: 48,

    [theme.fn.smallerThan("md")]: {
      fontSize: 32,
    },

    [theme.fn.smallerThan("xs")]: {
      fontSize: 24,
    },
  },

  innerTitle: {
    fontSize: 16,
    marginTop: 16,
  },

  line: {
    ".mantine-Timeline-item": {
      display: "flex",
      alignItems: "center",
      paddingBottom: 24,
      paddingLeft: 32,

      "&::before": {
        borderColor: theme.colors.cyan[6],
      },

      ".mantine-Timeline-itemBody": {
        fontSize: 16,
      },

      ".mantine-Timeline-itemTitle": {
        fontWeight: 400,
      },

      ".mantine-Timeline-itemBullet": {
        top: -10,
        background: theme.fn.linearGradient(90, theme.colors.blue[6], theme.colors.cyan[6]),
        borderColor: theme.colors.cyan[6],

      },
    },
  },

  inner: {
    display: "flex",
    justifyContent: "space-evenly",
    zIndex: 1,

    [theme.fn.smallerThan(1024)]: {
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column-reverse",
      gap: 32,
    },
  },

  content: {
    maxWidth: "50%",
    order: 1,

    [theme.fn.smallerThan(1140)]: {
      maxWidth: "60%",
    },

    [theme.fn.smallerThan(1024)]: {
      maxWidth: "70%",
      order: 0,
    },

    [theme.fn.smallerThan(660)]: {
      maxWidth: "100%",
    },
  },

  rightBox: {
    flex: 1,
    maxWidth: "50%",
    position: "relative",
    zIndex: 1,
    marginRight: 40,
    order: 0,

    [theme.fn.smallerThan(1140)]: {
      marginRight: 20,
      maxWidth: "40%",
    },

    [theme.fn.smallerThan(1024)]: {
      marginRight: 0,
      maxWidth: "100%",
      order: 1,
      marginBottom: 64,
    },
  },

  svg: {
    position: "absolute",
    height: "100%",
    right: 0,
    width: "calc(100% + 70px)",
    transform: "skewY(6deg)",
    zIndex: -1,

    [theme.fn.smallerThan(660)]: {
      width: "110%",
    },
  },

  buttonSmall: {
    marginTop: 64,

    [theme.fn.smallerThan("md")]: {
      marginTop: 24,
    },
  },

  list: {
    margin: 0,
    padding: 0,
    listStyle: "none",

    "li": {
      listStyle: "none",
      position: "absolute",

      "&:first-of-type": {
        background: theme.fn.gradient({ from: theme.colors.blue[6], to: theme.colors.cyan[6], deg: 30 }),
        bottom: "9%",
        boxShadow: `0 20px 50px 0 ${theme.fn.rgba(theme.colors.blue[6], 0.3)}`,
        padding: "10px 25px",
        right: 0,
        transform: "skewY(-12deg)",
        borderRadius: 4,

        "span": {
          lineHeight: 1.2,
          fontWeight: 600,
          fontSize: 65,
          color: theme.white,

          [theme.fn.smallerThan(1440)]: {
            fontSize: 55,
          },

          [theme.fn.smallerThan("md")]: {
            fontSize: 48,
          },

          [theme.fn.smallerThan("sm")]: {
            fontSize: 36,
          },

          [theme.fn.smallerThan("xs")]: {
            fontSize: 24,
            textAlign: "center",
          },
        },

        [theme.fn.smallerThan(1140)]: {
          bottom: "25%",
        },

        [theme.fn.smallerThan(1024)]: {
          bottom: "5%",
        },

        [theme.fn.smallerThan(768)]: {
          bottom: "10%",
          right: "50%",
          width: "max-content",
          transform: "translateX(50%) skewY(-12deg)",
        },

        [theme.fn.smallerThan(660)]: {
          padding: "8px 12px",
          bottom: "6%",
        },

        [theme.fn.smallerThan(540)]: {
          bottom: "8%",
        },

        [theme.fn.smallerThan(480)]: {
          bottom: "10%",
        },


        [theme.fn.smallerThan(375)]: {
          bottom: "12%",
        },
      },

      "&:nth-of-type(2)": {
        background: theme.black,
        bottom: "3%",
        boxShadow: `20px 20px 40px 0 ${theme.fn.rgba(theme.black, 0.4)}`,
        padding: "10px 25px",
        right: "7%",
        transform: "skewY(-12deg)",
        borderRadius: 4,

        "span": {
          lineHeight: 1,
          fontWeight: 600,
          fontSize: 30,
          letterSpacing: "2px",
          color: theme.white,

          [theme.fn.smallerThan(1440)]: {
            fontSize: 24,
          },

          [theme.fn.smallerThan("md")]: {
            fontSize: 20,
          },

          [theme.fn.smallerThan("sm")]: {
            letterSpacing: "1px",
            fontSize: 18,
          },

          [theme.fn.smallerThan("xs")]: {
            fontSize: 16,
            textAlign: "center",
          },
        },

        [theme.fn.smallerThan(1140)]: {
          bottom: "18%",
        },

        [theme.fn.smallerThan(1024)]: {
          bottom: 0,
        },

        [theme.fn.smallerThan(768)]: {
          right: "50%",
          width: "max-content",
          transform: "translateX(50%) skewY(-12deg)",
        },

        [theme.fn.smallerThan(660)]: {
          padding: "6px 12px",
        },
      },
    },
  },

  image: {
    position: "relative",

    "&::after": {
      content: "''",
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      height: "30%",
      background: "linear-gradient(transparent, white)",
    },

    width: "70%",
    maxWidth: 540,

    [theme.fn.smallerThan(660)]: {
      width: "100%",
    },
  },
}))

export function Program(): React.JSX.Element {
  const { classes, theme } = useStyles()
  const md = useMediaQuery(`(max-width: 768px)`)

  return (
    <div id="program" className={classes.wrapper}>
      <Container size={1320}>
        <Flex className={classes.inner}>

          <Flex direction="column" justify="flex-end" className={classes.rightBox}>
            <Image className={classes.image} objectFit="contain" src="assets/program1.png"
                   sx={{ margin: "0 auto auto" }}/>

            <svg className={classes.svg} viewBox="0 0 596 803" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M300.08 326.885L5.89685 422.456V367.302L300.08 271.746V326.885Z"
                    fill="url(#paint0_linear_53_55)"/>
              <path d="M594.417 371.718L154.49 514.145V435.989L594.417 293.562V371.718Z" fill={theme.colors.blue[6]}/>
              <path d="M533.277 239.231L92.5232 382.16V376.355L533.277 233.426V239.231Z" fill={theme.colors.blue[6]}/>
              <path d="M592.417 134.076L151.663 277.02V271.215L592.417 128.271V134.076Z" fill={theme.colors.blue[6]}/>
              <path d="M593.356 109.22L107.549 266.762V260.365L593.356 102.823V109.22Z"
                    fill="url(#paint1_linear_53_55)"/>
              <path d="M587.358 216L3.2937 400.915L0.690552 388.073L584.755 203.158L587.358 216Z" fill="white"/>
              <path d="M595 334.44L242.565 448.733L242.674 440.158L595 325.912V334.44Z" fill="white"/>
              <path d="M585.356 284.285L45.7017 459.289V437.952L585.356 262.949V284.285Z" fill={theme.colors.blue[6]}/>
              <path d="M401.917 216.712L33.7017 336.125V321.574L401.917 202.161V216.712Z"
                    fill="url(#paint2_linear_53_55)"/>
              <path d="M595.356 178.583L74.377 347.532V326.927L595.356 157.993V178.583Z"
                    fill="url(#paint3_linear_53_55)"/>
              <path d="M593.387 233.927L142.253 380.233V358.896L593.387 212.606V233.927Z"
                    fill="url(#paint4_linear_53_55)"/>
              <path d="M437.55 288.331L65.4284 409.005V404.103L437.55 283.429V288.331Z" fill={theme.colors.blue[6]}/>
              <defs>
                <linearGradient id="paint0_linear_53_55" x1="5.90132" y1="347.096" x2="300.081" y2="347.096"
                                gradientUnits="userSpaceOnUse">
                  <stop offset="1" stopColor={theme.colors.blue[9]}/>
                  <stop offset="1" stopColor={theme.colors.blue[6]}/>
                </linearGradient>
                <linearGradient id="paint1_linear_53_55" x1="107.547" y1="184.797" x2="593.358" y2="184.797"
                                gradientUnits="userSpaceOnUse">
                  <stop offset="1" stopColor={theme.colors.blue[9]}/>
                  <stop offset="1" stopColor={theme.colors.blue[6]}/>
                </linearGradient>
                <linearGradient id="paint2_linear_53_55" x1="33.7026" y1="269.142" x2="401.918" y2="269.142"
                                gradientUnits="userSpaceOnUse">
                  <stop offset="1" stopColor={theme.colors.blue[9]}/>
                  <stop offset="1" stopColor={theme.colors.blue[6]}/>
                </linearGradient>
                <linearGradient id="paint3_linear_53_55" x1="74.3779" y1="252.758" x2="595.358" y2="252.758"
                                gradientUnits="userSpaceOnUse">
                  <stop offset="1" stopColor={theme.colors.blue[9]}/>
                  <stop offset="1" stopColor={theme.colors.blue[6]}/>
                </linearGradient>
                <linearGradient id="paint4_linear_53_55" x1="142.26" y1="296.413" x2="593.388" y2="296.413"
                                gradientUnits="userSpaceOnUse">
                  <stop offset="1" stopColor={theme.colors.blue[9]}/>
                  <stop offset="1" stopColor={theme.colors.blue[6]}/>
                </linearGradient>
              </defs>
            </svg>

            <ul className={classes.list}>
              <li><span>Программа обучения</span></li>
              <li><span>9 учебных модулей</span></li>
            </ul>

          </Flex>

          <Box className={classes.content}>
            <Timeline className={classes.line} active={11} bulletSize={40} lineWidth={3} radius={4}>
              <Timeline.Item fw={400} lineVariant="dashed" bullet={<IconGavel size={24}/>}
                             title="Нормативно-правовые аспекты фитнеса"/>
              <Timeline.Item lineVariant="dashed" bullet={<IconMicroscope size={24}/>}
                             title="Медико-биологические основы фитнеса"/>
              <Timeline.Item lineVariant="dashed" bullet={<IconLungs size={24}/>}
                             title="Нормальная анатомия человека"/>
              <Timeline.Item lineVariant="dashed" bullet={<IconHeartbeat size={24}/>}
                             title="Нормальная физиология человека"/>
              <Timeline.Item lineVariant="dashed" bullet={<IconShieldHalfFilled size={24}/>}
                             title="Частная физиология"/>
              <Timeline.Item lineVariant="dashed" bullet={<IconYoga size={24}/>}
                             title="Фитнес тестирование и оценка показателей физического состояния человека"/>
              <Timeline.Item lineVariant="dashed" bullet={<IconStretching size={24}/>}
                             title="Основы биомеханики движений человека и техника выполнения физических упражнений"/>
              <Timeline.Item lineVariant="dashed" bullet={<IconVectorTriangle size={24}/>}
                             title="Принципы построения тренировочных программ"/>
              <Timeline.Item lineVariant="dashed" bullet={<IconFirstAidKit size={24}/>}
                             title="Особенности работы в тренажерном зале и принципы оказания первой помощи. Основы безопасности во время фитнес-тренировок"/>
              <Timeline.Item lineVariant="dashed" bullet={<IconReportMoney size={24}/>}
                             title="Техника продаж и удержания клиентов"/>
            </Timeline>
          </Box>

        </Flex>

        <Center className={classes.buttonSmall}>
          <Button
            radius={4}
            variant="gradient"
            gradient={{ from: "blue", to: "cyan", deg: 105 }}
            size={md ? "md" : "lg"}
            component="a"
            href="https://t.me/programm_pt_bot"
            target="_blank"
          >
            {md ? "Получить программу" : "Получить подробную программу обучения"}
          </Button>
        </Center>

      </Container>
    </div>

  )
}
