import { Text, Container, Center, createStyles, Title, Tabs, Group, Flex, Button, Box } from "@mantine/core"
import React from "react"
import { Image } from "../../shared/ui"
import { FormModalStore } from "../../app/FormModalStore"
import { useMediaQuery } from "@mantine/hooks"
import { AppTitle } from "../AppTitle"

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: `calc(${theme.spacing.xl} * 4)`,
    paddingBottom: `calc(${theme.spacing.xl} * 4)`,

    [theme.fn.smallerThan("md")]: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    },

    [theme.fn.smallerThan("xs")]: {
      paddingTop: `calc(${theme.spacing.xl} * 1.5)`,
      paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },

    zIndex: 1,
    position: "relative",

    "&::before": {
      content: "''",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,

      opacity: 0.3,
      backgroundImage: "url(assets/subjects.png)",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top center",
      backgroundSize: "cover",
      transform: "rotate(180deg)",
    },
  },

  image: {
    marginTop: 24,
    flex: 1,
    width: "100%",
    height: 640,

    [theme.fn.smallerThan("md")]: {
      height: 520,
    },

    [theme.fn.smallerThan("sm")]: {
      height: 420,
    },

    [theme.fn.smallerThan("xs")]: {
      height: 300,
    },
  },

  buttonBox: {
    marginTop: 48,
  },
}))

export function Docs(): React.JSX.Element {
  const { classes } = useStyles()
  const md = useMediaQuery(`(max-width: 768px)`)

  return (
    <div className={classes.wrapper}>

      <Container sx={{ position: "relative" }} size={1320}>
        <AppTitle
          accent="Документы"
          postTitle="после прохождения обучения"
        />

        <Text mb={24} align="center" c="dimmed">Сертификат, и удостоверение в твердом переплете с вкладышем</Text>

        <Center>
          <Tabs color="blue" variant="pills" defaultValue="solid">
            <Tabs.List grow>
              <Tabs.Tab value="solid">Твердый переплет</Tabs.Tab>
              <Tabs.Tab value="diploma">Диплом</Tabs.Tab>
              <Tabs.Tab value="certificate">Сертификат</Tabs.Tab>
              <Tabs.Tab value="reference">Справка</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="solid" pt="xs">
              <Image
                objectFit="contain"
                sx={{ borderRadius: 4 }}
                src="assets/docs/solid_example.png"
                className={classes.image}
              />
            </Tabs.Panel>

            <Tabs.Panel value="diploma" pt="xs">
              <Image
                objectFit="contain"
                sx={{ borderRadius: 4 }}
                src="assets/docs/диплом_образец.jpg"
                className={classes.image}
              />
            </Tabs.Panel>

            <Tabs.Panel value="certificate" pt="xs">
              <Image
                objectFit="contain"
                sx={{ borderRadius: 4 }}
                src="assets/docs/сертификат_образец.jpg"
                className={classes.image}
              />
            </Tabs.Panel>
            <Tabs.Panel value="reference" pt="xs">
              <Image
                objectFit="contain"
                sx={{ borderRadius: 4 }}
                src="assets/docs/справка_образец.jpg"
                className={classes.image}
              />
            </Tabs.Panel>
          </Tabs>

        </Center>

        <Group className={classes.buttonBox} position="center">
          <Flex align="center" justify="center" direction="column">
            <Button
              variant="gradient"
              gradient={{ from: "blue", to: "cyan", deg: 105 }}
              size={md ? "md" : "lg"}
              radius={4}
              onClick={() => FormModalStore.setData({ target: "Профессия: Фитнес-тренер. Блок: Документы. Кнопка: Получить консультацию" })}
            >
              Получить консультацию
            </Button>
          </Flex>
        </Group>

      </Container>
    </div>

  )
}
