import {
  createStyles,
  Container,
  Flex,
  Box,
  SimpleGrid,
} from "@mantine/core"
import React from "react"
import { Image } from "../../shared/ui"

const useStyles = createStyles((theme) => ({
  wrapper: {
    zIndex: 1,
    paddingTop: `calc(${theme.spacing.xl} * 2)`,
    paddingBottom: `calc(${theme.spacing.xl} * 2)`,

    [theme.fn.smallerThan("md")]: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    },

    [theme.fn.smallerThan("xs")]: {
      paddingTop: `calc(${theme.spacing.xl} * 1.5)`,
      paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },
    position: "relative",
    // overflow: "hidden",

    "&::after": {
      content: "\"\"",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: "100%",
      height: "100%",
      backgroundColor: theme.fn.rgba(theme.colors.blue[6], 0.1),
      transform: "skewY(2.5deg)",
    },

    "&::before": {
      content: "\"\"",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: "100%",
      height: "100%",
      backgroundColor: theme.fn.rgba(theme.colors.blue[6], 0.92),
      transform: "skewY(-2.5deg)",
      backgroundImage: "repeating-linear-gradient(150deg,rgba(255,255,255,.05),rgba(255,255,255,.05) 1px,transparent 0,transparent 41px),repeating-linear-gradient(-150deg,rgba(255,255,255,.05),rgba(255,255,255,.05) 1px,transparent 0,transparent 41px)",
      backgroundSize: "82px 47px",
    },
  },

  element: {
    zIndex: 1,
    position: "relative",
    padding: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",

    [theme.fn.smallerThan(768)]: {
      padding: 16,
    },
  },

  elementContent: {
    textAlign: "center",
    fontSize: 18,
    paddingTop: 30,
    fontWeight: 500,
    color: theme.white,

    [theme.fn.smallerThan(768)]: {
      fontSize: 16,
      paddingTop: 24,
    },

  },
}))

export function About(): React.JSX.Element {
  const { classes } = useStyles()

  return (
    <div className={classes.wrapper}>
      <Container size={1320}>
        <SimpleGrid
          cols={4}
          spacing={24}
          verticalSpacing={24}
          breakpoints={[
            { maxWidth: "lg", cols: 4 },
            { maxWidth: "md", cols: 2 },
            { maxWidth: "xs", cols: 1 },
          ]}
        >
          <Box className={classes.element}>
            <Image src="assets/check.png" objectFit="contain"/>
            <Flex className={classes.elementContent}>
              Образовательный курс с нуля до специалиста
            </Flex>
          </Box>
          <Box className={classes.element}>
            <Image src="assets/check.png" objectFit="contain"/>
            <Flex className={classes.elementContent}>
              Диплом о профессиональной переподготовке
            </Flex>
          </Box>
          <Box className={classes.element}>
            <Image src="assets/check.png" objectFit="contain"/>
            <Flex className={classes.elementContent}>
              Физкультурное образование не требуется
            </Flex>
          </Box>
          <Box className={classes.element}>
            <Image src="assets/check.png" objectFit="contain"/>
            <Flex className={classes.elementContent}>
              Только доказательный научный подход
            </Flex>
          </Box>
        </SimpleGrid>
      </Container>
    </div>
  )
}
