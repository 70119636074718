import { Text, Container, Title, createStyles, Button, Flex } from "@mantine/core"
import React from "react"
import { Image } from "../shared/ui"
import { useMediaQuery } from "@mantine/hooks"
import { Link } from "react-scroll"

// ----------------------------------------------------------------------

const useStyles = createStyles((theme) => {
  return ({
    container: {
      paddingTop: `calc(${theme.spacing.xl} * 4)`,
      paddingBottom: `calc(${theme.spacing.xl} * 4)`,

      [theme.fn.smallerThan("md")]: {
        paddingTop: `calc(${theme.spacing.xl} * 2)`,
        paddingBottom: `calc(${theme.spacing.xl} * 2)`,
      },

      [theme.fn.smallerThan("xs")]: {
        paddingTop: `calc(${theme.spacing.xl} * 1.5)`,
        paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
      },
    },

    contentWrapper: {
      padding: "0 64px",
      borderRadius: 8,
      gap: 64,

      [theme.fn.smallerThan(1024)]: {
        padding: "0 48px",
        gap: 24,
      },

      [theme.fn.smallerThan(768)]: {
        padding: "0 20px",
        gap: 24,
        flexDirection: "column",
      },
    },

    content: {
      maxWidth: 640,
      gap: 24,
      fontSize: theme.fontSizes.lg,

      [theme.fn.smallerThan(768)]: {
        order: 1,
        fontSize: theme.fontSizes.md,
      },
    },

    image: {
      justifySelf: "flex-start",
      minWidth: 248,
      margin: "0 auto",
      filter: "drop-shadow(0 0 20px rgba(0,0,0,0.3))",

      [theme.fn.smallerThan(768)]: {
        justifySelf: "unset",
        order: 0,
      },
    },

    title: {
      fontSize: 36,

      [theme.fn.smallerThan("md")]: {
        fontSize: 24,
        fontWeight: 600,
      },
    },

    btn: {
      [theme.fn.smallerThan(768)]: {
        alignSelf: "center",
      },
    },

    text: {
      fontSize: 18,

      [theme.fn.smallerThan(768)]: {
        fontSize: 16,
      },
    },
  })
})

// ----------------------------------------------------------------------

export function Gpt(): React.JSX.Element {
  const { classes } = useStyles()
  const md = useMediaQuery(`(max-width: 768px)`)
  const xs = useMediaQuery(`(max-width: 576px)`)

  const offset = md ? -48 : xs ? -36 : -72

  return (
    <Container size={1320} className={classes.container}>
      <Flex align="center" justify="center" className={classes.contentWrapper}>
        <Flex direction="column" align="flex-start" className={classes.content}>
          <Title className={classes.title}>
            <Text
              span
              variant="gradient"
              gradient={{
                from: "blue",
                to: "cyan",
                deg: 105,
              }}
            >
              BodyCoach GPT&nbsp;
            </Text>
            - главная фишка обучающего курса &#34;Профессия: Фитнес-тренер&#34;
          </Title>
          <Text className={classes.text}>
            Онлайн-куратор с искусственным интеллектом. Помогает в изучении материала и отвечает на ваши вопросы в сфере
            фитнеса и нутрициологии. На связи 24/7
          </Text>
          <Button
            className={classes.btn}
            radius={4}
            size={md ? "md" : "lg"}
            variant="gradient"
            gradient={{ from: "blue", to: "cyan", deg: 105 }}
            to={"prices"}
            smooth={true}
            offset={offset}
            duration={1000}
            component={Link}
          >
            Начать обучение
          </Button>
        </Flex>
        <Image ratio="590/1192" className={classes.image} src="assets/bot.png"
               sx={{ borderRadius: 20, maxWidth: 248 }}/>
      </Flex>
    </Container>
  )
}
