import {
  Text,
  Container,
  ThemeIcon,
  Title,
  SimpleGrid,
  createStyles,
  rem,
  AspectRatio, Box,
} from "@mantine/core"
import { IconBooks, IconBrandHipchat, IconPencil, IconSchool } from "@tabler/icons-react"
import { useMediaQuery } from "@mantine/hooks"
import React from "react"
import { AppTitle } from "./AppTitle"

const data = [
  {
    Icon: IconBooks,
    description: "Изучаете материал на платформе в любое удобное время",
  },
  {
    Icon: IconBrandHipchat,
    description: "Общаетесь с экспертами и единомышленниками в Telegram",
  },
  {
    Icon: IconPencil,
    description: "Выполняете практические задания, получаете обратную связь и закрепляете знания",
  },
  {
    Icon: IconSchool,
    description: "Сдаете тестирование и получаете образовательные документы",
  },
]

const useStyles = createStyles((theme) => ({
  inner: {
    paddingTop: `calc(${theme.spacing.xl} * 4)`,
    paddingBottom: `calc(${theme.spacing.xl} * 4)`,

    [theme.fn.smallerThan("md")]: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    },

    [theme.fn.smallerThan("xs")]: {
      paddingTop: `calc(${theme.spacing.xl} * 1.5)`,
      paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },
  },

  item: {
    display: "flex",
  },

  itemIcon: {
    padding: theme.spacing.xs,
    marginRight: theme.spacing.md,
  },

  description: {
    textAlign: "center",
    marginTop: theme.spacing.xs,
  },

  videoContainer: {
    borderRadius: 8,
    overflow: "hidden",
  },

  video: {
    height: "100%",
    objectFit: "contain",
    width: "100%",
    position: "relative",
  },

  text: {
    fontSize: 18,

    [theme.fn.smallerThan(768)]: {
      fontSize: 16,
    },
  },
}))

export function FourSteps(): React.JSX.Element {
  const { classes, theme } = useStyles()
  const matches = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)

  const items = data.map(({ description, Icon }, index) => (
    <div className={classes.item} key={index}>
      <ThemeIcon size="xl" variant="gradient" gradient={{ from: "blue", to: "cyan" }} className={classes.itemIcon}>
        <Icon size="2rem"/>
      </ThemeIcon>

      <Text className={classes.text}>
        {description}
      </Text>
    </div>
  ))

  return (
    <Container size="xl" className={classes.inner}>
      <AppTitle
        accent="4 шага"
        postTitle="к переменам в карьере и жизни"
      />

      <AspectRatio ratio={matches ? 4 / 3 : 21 / 9} className={classes.videoContainer}>
        <video
          className={classes.video}
          poster="assets/promo-poster.jpg"
          controlsList="nodownload"
          muted
          loop
          playsInline
          autoPlay
          src={
            matches
              ? "assets/promo_mobile.mp4"
              : "assets/promo.mp4"
          }
        />
      </AspectRatio>

      <SimpleGrid
        cols={2}
        spacing={48}
        breakpoints={[ { maxWidth: 550, cols: 1, spacing: 24, verticalSpacing: 24 } ]}
        mt={48}
      >
        {items}
      </SimpleGrid>
    </Container>
  )
}
