import { Slide, SlideImage } from "yet-another-react-lightbox"
import { Lightbox, useLightBox } from "../lightbox"
import { Container, createStyles, SimpleGrid, Stack, px } from "@mantine/core"
import { Image } from "../../shared/ui"
import { useCallback } from "react"

// ----------------------------------------------------------------------

const slides: Slide[] = [
  { src: "assets/lightBox/1.jpg" },
  { src: "assets/lightBox/2.jpg" },
  { src: "assets/lightBox/3.jpg" },
  { src: "assets/lightBox/4.jpg" },
  { src: "assets/lightBox/5.jpg" },
  { src: "assets/lightBox/6.jpg" },
  { src: "assets/lightBox/7.jpg" },
]

const getChild = (height: number, slide: Slide, open: (thumbnail: string) => void) => {
  const thumbnail = (slide as SlideImage).src

  return (
    <Image
      key={thumbnail}
      alt={thumbnail}
      src={thumbnail}
      onClick={() => open(thumbnail)}
      sx={{
        height,
        borderRadius: 4,
        cursor: "pointer",
        objectPosition: "center",
      }}
    />
  )
}

const BASE_HEIGHT = 640
const getSubHeight = (children: number, spacing: number) =>
  BASE_HEIGHT / children - spacing * ((children - 1) / children)

// ----------------------------------------------------------------------

const useStyles = createStyles((theme) => ({
    wrapper: {
      position: "relative",

      paddingTop: `calc(${theme.spacing.xl} * 4)`,
      paddingBottom: `calc(${theme.spacing.xl} * 4)`,

      [theme.fn.smallerThan("md")]: {
        paddingTop: `calc(${theme.spacing.xl} * 3)`,
        paddingBottom: `calc(${theme.spacing.xl} * 2)`,
      },

      [theme.fn.smallerThan("xs")]: {
        paddingTop: `calc(${theme.spacing.xl} * 2)`,
        paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
      },
    },
  }
))

export default function LightboxView() {
  const lightbox = useLightBox(slides)
  const { classes, theme } = useStyles()

  const open = useCallback((thumbnail: string) => lightbox.onOpen(`${thumbnail}`), [ lightbox ])

  return (
    <>
      <Container className={classes.wrapper} size={1320}>

        <SimpleGrid
          cols={2}
          spacing={24}
          verticalSpacing={24}
          breakpoints={[
            { maxWidth: "xs", cols: 1 },
          ]}
        >
          {getChild(BASE_HEIGHT, slides[0], open)}
          <Stack spacing={24}>
            {getChild(getSubHeight(2, px(theme.spacing.xl) as number), slides[1], open)}
            {getChild(getSubHeight(2, px(theme.spacing.xl) as number), slides[2], open)}
          </Stack>
          <Stack spacing={24}>
            {getChild(getSubHeight(3, px(theme.spacing.xl) as number), slides[3], open)}
            {getChild(getSubHeight(3, px(theme.spacing.xl) as number), slides[4], open)}
            {getChild(getSubHeight(3, px(theme.spacing.xl) as number), slides[5], open)}
          </Stack>
          {getChild(BASE_HEIGHT, slides[6], open)}

        </SimpleGrid>
      </Container>

      <Lightbox
        open={lightbox.open}
        close={lightbox.onClose}
        slides={slides}
        index={lightbox.selected}
      />
    </>

  )
}
