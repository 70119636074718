import {
  createStyles,
  Title,
  Container,
  Text,
  Flex,
  Box,
  SimpleGrid,
} from "@mantine/core"
import React from "react"
import { Image } from "../../shared/ui"
import { AppTitle } from "../AppTitle"

const useStyles = createStyles((theme) => ({
  wrapper: {
    zIndex: 1,
    position: "relative",

    paddingTop: `calc(${theme.spacing.xl} * 4)`,
    paddingBottom: `calc(${theme.spacing.xl} * 4)`,

    [theme.fn.smallerThan("md")]: {
      paddingTop: `calc(${theme.spacing.xl} * 3)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    },

    [theme.fn.smallerThan("xs")]: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },

    "&::before": {
      content: "''",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,

      opacity: 0.3,
      backgroundImage: "url(assets/subjects.png)",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top center",
      backgroundSize: "cover",
    },
  },

  element: {
    zIndex: 1,
    position: "relative",
    marginBottom: 15,
    marginTop: 40,
    padding: 30,

    "&::after": {
      content: "''",
      background: theme.fn.rgba(theme.colors.blue[6], 0.1),
      height: "100%",
      left: 0,
      top: 0,
      position: "absolute",
      transform: "skewY(5deg)",
      width: "100%",
      zIndex: -1,
      borderRadius: 4,
    },
  },

  elementIcon: {
    alignItems: "center",
    background: theme.white,
    boxShadow: `0 -10px 50px 0 ${theme.colors.blue[1]}`,
    display: "flex",
    height: 120,
    justifyContent: "center",
    marginTop: -70,
    borderRadius: 4,
    width: 120,
  },

  elementContent: {
    fontSize: 18,
    lineHeight: 1.5,
    paddingTop: 30,
    paddingBottom: 15,

    [theme.fn.smallerThan(768)]: {
      fontSize: 16,
    },
  },
}))

export function Subjects(): React.JSX.Element {
  const { classes } = useStyles()

  return (
    <div className={classes.wrapper}>
      <Container size={1320}>
        <AppTitle
          accent="Как проходит"
          postTitle="обучение?"
        />

        <SimpleGrid
          cols={3}
          spacing={24}
          verticalSpacing={24}
          breakpoints={[
            { maxWidth: "lg", cols: 3 },
            { maxWidth: "md", cols: 2 },
            { maxWidth: "xs", cols: 1 },
          ]}
        >
          <Box className={classes.element}>
            <Box className={classes.elementIcon}>
              <Image src="assets/subjects/lecture.png" objectFit="contain"/>
            </Box>
            <Flex direction="column" gap={30} className={classes.elementContent}>
              <Text fw={600}>Лекции и задания</Text>
              <Text>Более 100 структурированных уроков с презентациями по каждой теме и задания на закрепление
                пройденного материала.</Text>
            </Flex>
          </Box>

          <Box className={classes.element}>
            <Box className={classes.elementIcon}>
              <Image src="assets/subjects/theory.png" objectFit="contain"/>
            </Box>
            <Flex direction="column" gap={30} className={classes.elementContent}>
              <Text fw={600}>Из теории в практику</Text>
              <Text>Получение практических навыков, биомеханический анализ выполнения упражнений, разбор ситуационных
                задач в учебном чате.</Text>
            </Flex>
          </Box>

          <Box className={classes.element}>
            <Box className={classes.elementIcon}>
              <Image src="assets/subjects/unlimited-storage.png" objectFit="contain"/>
            </Box>
            <Flex direction="column" gap={30} className={classes.elementContent}>
              <Text fw={600}>Бессрочный доступ к материалам</Text>
              <Text>Все учебные материалы размещены на образовательной платформе, вы получаете бессрочный доступ к
                лекциям и дополнительным материалам.</Text>
            </Flex>
          </Box>

          <Box className={classes.element}>
            <Box className={classes.elementIcon}>
              <Image src="assets/subjects/social-media.png" objectFit="contain"/>
            </Box>
            <Flex direction="column" gap={30} className={classes.elementContent}>
              <Text fw={600}>Чат студентов и преподавателей</Text>
              <Text>Образовательный процесс проходит с учебным чатом для тематического общения и обсуждения материалов
                обучения.</Text>
            </Flex>
          </Box>

          <Box className={classes.element}>
            <Box className={classes.elementIcon}>
              <Image src="assets/subjects/live-chat.png" objectFit="contain"/>
            </Box>
            <Flex direction="column" gap={30} className={classes.elementContent}>
              <Text fw={600}>Поддержка куратора</Text>
              <Text>Со студентами работают кураторы, которые помогают разобраться в учебном процессе, проверяют задания,
                проводят разбор задач и отвечают на вопросы.</Text>
            </Flex>
          </Box>

          <Box className={classes.element}>
            <Box className={classes.elementIcon}>
              <Image src="assets/subjects/quiz.png" objectFit="contain"/>
            </Box>
            <Flex direction="column" gap={30} className={classes.elementContent}>
              <Text fw={600}>Промежуточные тесты и итоговый экзамен</Text>
              <Text>В процессе обучения вам предстоит пройти несколько тестирований для закрепления материала и сдать
                итоговый экзамен для получения образовательных документов.</Text>
            </Flex>
          </Box>
        </SimpleGrid>
      </Container>
    </div>
  )
}
