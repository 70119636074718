import "./App.css"
import "@fontsource/montserrat/400.css"
import "@fontsource/montserrat/500.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/700.css"
import "@fontsource/montserrat/900.css"
import "react-lazy-load-image-component/src/effects/blur.css"

/* lightbox */
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';


import { MantineProvider, MantineThemeOverride } from "@mantine/core"
import React, { Suspense } from "react"
import { LoadingScreen, BackToTop } from "../shared/ui"
import { MainPage } from "../pages"
import { FormModal, PrivacyModal } from "../components"
import { useMantineTheme } from "@mantine/core"

// ----------------------------------------------------------------------

export function App(): React.JSX.Element {
  const theme = useMantineTheme()

  const themeOverride: MantineThemeOverride = {
    fontFamily: "Montserrat, sans-serif",
    black: "#222222",
  }

  return (<MantineProvider theme={themeOverride} withGlobalStyles withNormalizeCSS>
      <Suspense fallback={<LoadingScreen/>}>
        <MainPage/>
        <FormModal/>
        <PrivacyModal/>
      </Suspense>
      <BackToTop/>
    </MantineProvider>
  )
}
