import { Box, createStyles, keyframes } from "@mantine/core"
import { CSSProperties } from "react"

const rotate = keyframes({
  "from": { transform: "rotate(0deg)" },
  "to": { transform: "rotate(360deg)" },
})

const useStyles = createStyles((theme) => ({
  box: {
    position: "absolute",
    zIndex: -1,
    userSelect: "none",
    pointerEvents: "none",
    width: "calc(100vw * 0.5)",
    maxWidth: "800px",
    maxHeight: "800px",
    height: "calc(100vw * 0.5)",
    minWidth: 288,
    minHeight: "calc(830/631 * 288px)",
  },

  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    animation: `${rotate} 20s linear infinite`,
  },
}))

type Props = {
  styles?: CSSProperties
}

export function Circle({ styles }: Props) {
  const { classes } = useStyles()

  return (
    <Box style={styles} className={classes.box}>
      <img src="assets/circle.svg" className={classes.image}/>
    </Box>
  )
}
