import {
  createStyles,
  Container,
  Flex,
  SimpleGrid,
  Title,
  Text,
  Button,
} from "@mantine/core"
import React from "react"
import { Image } from "../../shared/ui"
import { useMediaQuery } from "@mantine/hooks"

const useStyles = createStyles((theme) => ({
  wrapper: {
    zIndex: 1,
    overflow: "hidden",
    paddingTop: `calc(${theme.spacing.xl} * 4)`,
    paddingBottom: `calc(${theme.spacing.xl} * 4)`,

    [theme.fn.smallerThan("md")]: {
      paddingTop: `calc(${theme.spacing.xl} * 3)`,
      paddingBottom: `calc(${theme.spacing.xl} * 3)`,
    },

    [theme.fn.smallerThan("xs")]: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    },

    position: "relative",
    color: theme.white,

    "&::after": {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: -1,
      transform: "skewY(2.5deg)",
      backgroundColor: theme.colors.blue[6],
      backgroundImage: "repeating-linear-gradient(150deg,rgba(255,255,255,.06),rgba(255,255,255,.06) 1px,transparent 0,transparent 41px),repeating-linear-gradient(-150deg,rgba(255,255,255,.06),rgba(255,255,255,.06) 1px,transparent 0,transparent 41px)",
      backgroundSize: "82px 47px",
    },

    "&::before": {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: -2,
      opacity: 0.15,
      transform: "skewY(-2.5deg)",
      backgroundColor: theme.colors.blue[5],
    },
  },

  title: {
    position: "relative",

    [theme.fn.smallerThan("md")]: {
      fontSize: 24,
      fontWeight: 600,
    },
  },

  right: {
    position: "relative",
  },

  backText: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%) rotate(-30deg)",
    position: "absolute",
    color: theme.white,
    fontSize: 110,
    lineHeight: 1,
    fontWeight: 900,
    letterSpacing: 3,
    opacity: 0.05,

    [theme.fn.smallerThan(1280)]: {
      fontSize: 80,
    },

    [theme.fn.smallerThan(1024)]: {
      fontSize: 60,
    },

    [theme.fn.smallerThan(768)]: {
      fontSize: 50,
    },
  },

  image: {
    [theme.fn.smallerThan(1024)]: {
      maxWidth: 200,
    },
  },

  text: {
    fontSize: 18,

    [theme.fn.smallerThan(768)]: {
      fontSize: 16,
    },
  },
}))

export function Licence(): React.JSX.Element {
  const { classes } = useStyles()
  const md = useMediaQuery(`(max-width: 768px)`)

  return (
    <div id="license" className={classes.wrapper}>

      <Container size={1320}>
        <SimpleGrid
          cols={2}
          spacing={64}
          verticalSpacing={48}
          breakpoints={[
            { maxWidth: "xs", cols: 1 },
          ]}
        >
          <Flex gap={24} direction="column">
            <Title className={classes.title} size={36} order={3}>
              Почему нам доверяют более 10.000 студентов?
            </Title>
            <Text className={classes.text}>
              Наша организация осуществляет образовательную деятельность на основании государственной лицензии
              и выдает документы установленного образца.
            </Text>
            <Text mt={24} className={classes.text}>№ Образовательной лицензии: Л035-01218-23/00592537</Text>
          </Flex>
          <Flex className={classes.right} gap={24} direction="column" align="center" justify="center">
            <div className={classes.backText}>ЛИЦЕНЗИЯ</div>

            <Image sx={{ maxWidth: 264 }} className={classes.image} src="assets/license.png"/>
            <Button
              color="dark"
              size={md ? "md" : "lg"}
              radius={4}
              component="a"
              target="_blanck"
              href="https://islod.obrnadzor.gov.ru/rlic/details/fc0ce866-2c42-f41f-cee1-67bd2a3787e2/"
            >
              Проверить лицензию
            </Button>
          </Flex>
        </SimpleGrid>
      </Container>
    </div>
  )
}
