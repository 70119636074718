import {
  createStyles,
  Title,
  Text,
  Box,
} from "@mantine/core"
import React from "react"

const useStyles = createStyles((theme) => ({
  title: {
    position: "relative",
    marginBottom: 64,
    paddingLeft: 60,

    [theme.fn.smallerThan("md")]: {
      marginBottom: 48,
    },

    [theme.fn.smallerThan("sm")]: {
      paddingLeft: 48,
    },

    [theme.fn.smallerThan("xs")]: {
      marginBottom: 24,
      paddingLeft: 0,
    },

    "&::after": {
      boxShadow: `0 0 20px 3px ${theme.fn.rgba(theme.colors.cyan[6], 0.15)}`,
      content: "''",
      height: "90%",
      position: "absolute",
      top: 5,
      transform: "skewX(-8deg)",
      backgroundColor: theme.colors.blue[6],
      background: theme.fn.gradient({ from: theme.colors.blue[6], to: theme.colors.cyan[6], deg: 20 }),
      left: 0,
      width: 15,

      [theme.fn.smallerThan("xs")]: {
        display: "none",
      },
    },

    "&::before": {
      boxShadow: `0 0 20px 3px ${theme.fn.rgba(theme.colors.cyan[6], 0.15)}`,
      content: "''",
      height: "90%",
      position: "absolute",
      top: 5,
      transform: "skewX(-8deg)",
      background: theme.fn.gradient({ from: theme.colors.cyan[6], to: theme.colors.blue[6], deg: 20 }),
      left: 23,
      width: 5,

      [theme.fn.smallerThan("xs")]: {
        display: "none",
      },
    },
  },

  titleOuter: {
    fontSize: 55,
    marginBottom: 15,

    [theme.fn.smallerThan("md")]: {
      fontSize: 48,
    },

    [theme.fn.smallerThan("sm")]: {
      fontSize: 36,
    },

    [theme.fn.smallerThan("xs")]: {
      fontSize: 24,
      textAlign: "center",
    },
  },
}))

type Props = {
  preTitle?: string
  postTitle?: string
  accent?: string
}

export function AppTitle({ preTitle = "", postTitle = "", accent = "" }: Props): React.JSX.Element {
  const { classes } = useStyles()

  return (
    <Box className={classes.title}>
      <Title order={2} className={classes.titleOuter}>
        {preTitle}
        {accent && (
          <Text
            span
            variant="gradient"
            gradient={{
              from: "blue",
              to: "cyan",
              deg: 105,
            }}
          >&nbsp;{accent}
          </Text>
        )}
        {postTitle ? ` ${postTitle}` : ""}
      </Title>
    </Box>
  )
}
