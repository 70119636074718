import {
  createStyles,
  Container,
  Flex,
  Box, Text, SimpleGrid, ThemeIcon,
} from "@mantine/core"
import React from "react"
import { Image } from "../../shared/ui"
import { IconInfinity } from "@tabler/icons-react"

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",

    paddingTop: `calc(${theme.spacing.xl} * 4)`,
    paddingBottom: `calc(${theme.spacing.xl} * 4)`,

    [theme.fn.smallerThan("md")]: {
      paddingTop: `calc(${theme.spacing.xl} * 3)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    },

    [theme.fn.smallerThan("xs")]: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },
  },

  col: {
    width: "calc(50% - 15px)",
    maxWidth: "100%",
    flex: "0 0 auto",

    [theme.fn.smallerThan(1024)]: {
      width: "calc(55% - 8px)",
    },

    [theme.fn.smallerThan(768)]: {
      width: "100%",
    },
  },

  rightCol: {
    marginTop: 40,

    [theme.fn.smallerThan(1440)]: {
      marginTop: 0,
    },

    [theme.fn.smallerThan(1024)]: {
      width: "calc(45% - 8px)",
    },

    [theme.fn.smallerThan(768)]: {
      width: "100%",
    },
  },

  imagesContainer: {
    marginTop: 40,
    paddingRight: 20,
    position: "relative",

    [theme.fn.smallerThan(1440)]: {
      paddingRight: 0,
      marginTop: 0,
    },
  },

  bigImage: {
    height: "100%",
    borderRadius: 4,
    width: "100%",
  },

  smallImage: {
    height: "auto",
    width: "100%",
    maxWidth: "100%",
    verticalAlign: "middle",
    borderRadius: "4px 0 4px 0",
  },

  imageBox: {
    width: 270,
    height: 270,
    top: -60,
    left: -70,
    position: "absolute",
    zIndex: 1,

    [theme.fn.smallerThan(1440)]: {
      width: 220,
      height: 220,
      top: 0,
      left: 0,
    },

    [theme.fn.smallerThan(540)]: {
      width: 160,
      height: 160,
    },

    "&::after": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 16,
      left: 16,
      zIndex: -1,
      background: theme.white,
      borderRadius: 4,

      [theme.fn.smallerThan(1440)]: {
        width: 252,
        height: 252,
        top: -16,
        left: -16,
      },

      [theme.fn.smallerThan(540)]: {
        width: 192,
        height: 192,
      },
    },
  },

  smallInfo: {
    textAlign: "center",
    display: "inline-block",
    position: "absolute",
    padding: 15,
    bottom: 0,
    right: 0,
    background: theme.colors.blue[6],
    textTransform: "uppercase",
    zIndex: 1,
    borderRadius: "4px 0 4px 0",

    [theme.fn.smallerThan(540)]: {
      padding: "6px 0",
      width: "100%",
      borderRadius: "0 0 4px 0",
    },

    "& > h2": {
      margin: 0,
      fontSize: 42,
      color: theme.white,
      fontWeight: 400,
      letterSpacing: 3,
      lineHeight: 1,

      [theme.fn.smallerThan(1440)]: {
        fontSize: 36,
      },

      [theme.fn.smallerThan(540)]: {
        fontSize: 20,
        letterSpacing: 1,
        marginBottom: 5,
      },
    },

    "& > h5": {
      margin: 0,
      fontSize: 14,
      letterSpacing: 1,
      color: theme.white,
      fontWeight: 400,
      lineHeight: 1,

      [theme.fn.smallerThan(540)]: {
        textTransform: "none",
      },
    },
  },

  headInfo: {
    position: "relative",
  },

  subtitle: {
    fontSize: 18,
    fontWeight: 400,
    color: "#a6a6a6",
    marginBottom: 10,
    marginTop: 0,
    lineHeight: 1.2,
    textTransform: "uppercase",

    [theme.fn.smallerThan(768)]: {
      fontSize: 16,
    },
  },

  title: {
    fontSize: 55,
    fontWeight: 700,
    lineHeight: 1,
    margin: 0,

    [theme.fn.smallerThan("md")]: {
      fontSize: 48,
    },

    [theme.fn.smallerThan("sm")]: {
      fontSize: 36,
    },

    [theme.fn.smallerThan("xs")]: {
      fontSize: 24,
    },
  },

  desc: {
    fontWeight: 500,
    fontSize: 20,
    marginBottom: 0,
    marginTop: 10,

    [theme.fn.smallerThan(768)]: {
      fontSize: 16,
    },
  },

  list: {
    padding: "15px 25px",
    borderLeft: `5px solid ${theme.colors.blue[6]}`,
    backgroundColor: theme.colors.blue[0],
    marginTop: 36,
    borderRadius: 4,
    fontWeight: 500,

    [theme.fn.smallerThan(1024)]: {
      marginTop: 16,
    },

    [theme.fn.smallerThan(768)]: {
      padding: "12px 16px",
      borderWidth: 4,
      fontWeight: 400,
    },
  },

  icon: {
    backgroundColor: theme.colors.blue[1],
    fontWeight: 600,
  },

  container: {
    [theme.fn.smallerThan(1024)]: {
      justifyContent: "space-between",
      gap: 16,
    },

    [theme.fn.smallerThan(768)]: {
      flexDirection: "column",
    },
  },

  innerListBox: {
    [theme.fn.smallerThan(1024)]: {
      display: "none",
    },
  },

  outerListBox: {
    [theme.fn.largerThan(1024)]: {
      display: "none",
    },
  },
}))

function List(): React.JSX.Element {
  const { classes } = useStyles()

  return (
    <SimpleGrid
      className={classes.list}
      cols={1}
      spacing={24}
      verticalSpacing={24}
    >
      <Flex gap={16} align="flex-start">
        <ThemeIcon variant="light" radius={4} size="xl" className={classes.icon}>
          200
        </ThemeIcon>
        <Text mt={6}>Более 200 лекций и практических заданий</Text>
      </Flex>

      <Flex gap={16}>
        <ThemeIcon variant="light" radius={4} size="xl" className={classes.icon}>
          360
        </ThemeIcon>
        <Text>360 часов образовательного процесса в соответствии с профессиональным стандартом министерства
          образования</Text>
      </Flex>

      <Flex gap={16} align="flex-start">
        <ThemeIcon variant="light" radius={4} size="xl" className={classes.icon}>
          9+
        </ThemeIcon>
        <Text mt={6}>Свыше 9 учебных модулей</Text>
      </Flex>

      <Flex gap={16}>
        <ThemeIcon variant="light" radius={4} size="xl" className={classes.icon}>
          14
        </ThemeIcon>
        <Text>14 экспертов в сфере фитнеса, спортивной физиологии и доказательной медицины</Text>
      </Flex>

      <Flex gap={16}>
        <ThemeIcon variant="light" radius={4} size="xl" className={classes.icon}>
          180
        </ThemeIcon>
        <Text>180 дней беспрерывного обучения с лучшими преподавателями России и СНГ</Text>
      </Flex>

      <Flex gap={16} align="flex-start">
        <ThemeIcon variant="light" radius={4} size="xl" className={classes.icon}>
          <IconInfinity/>
        </ThemeIcon>
        <Text mt={7}>Бессрочный доступ к материалам курса</Text>
      </Flex>

    </SimpleGrid>
  )
}

export function AboutCourse(): React.JSX.Element {
  const { cx, classes } = useStyles()

  return (
    <div className={classes.wrapper}>
      <Container size={1320}>
        <Flex gap={30} align="flex-start" className={classes.container}>
          <Box className={classes.col}>
            <Box className={classes.imagesContainer}>
              <Box className={classes.imageBox}>
                <Box className={classes.smallInfo}>
                  <h2>14</h2>
                  <h5>профессионалов</h5>
                </Box>
                <Image
                  src="assets/aboutCourse-small.jpg"
                  className={classes.smallImage}
                />
              </Box>
              <Image
                objectFit="cover"
                src="assets/aboutCourse-big.jpg"
                className={classes.bigImage}
                sx={{
                  objectPosition: "center",
                  clipPath: "polygon(50% 0%, 100% 0, 100% 80%, 85% 100%, 51% 100%, 0 100%, 0 0)",
                }}
              />
            </Box>
          </Box>

          <Box className={cx(classes.col, classes.rightCol)}>
            <Box className={classes.headInfo}>
              <h5 className={classes.subtitle}>Обучающий курс</h5>
              <h2 className={classes.title}>
                <Text
                  variant="gradient"
                  gradient={{
                    from: "blue",
                    to: "cyan",
                    deg: 105,
                  }}
                  sx={{ lineHeight: 1.2 }}
                  span
                >
                  Фитнес тренер
                </Text>
                &nbsp;-&nbsp;это
              </h2>
              <p className={classes.desc}>Чем мы отличаемся от остальных</p>

              <Box className={classes.innerListBox}>
                <List/>
              </Box>
            </Box>
          </Box>
        </Flex>

        <Box className={classes.outerListBox}>
          <List/>
        </Box>
      </Container>
    </div>
  )
}
