import React, { useEffect, useState } from "react"
import {
  Alert,
  Anchor,
  Button,
  Checkbox,
  Container,
  createStyles,
  Input,
  Modal,
  Stack,
  Text,
  TextInput,
} from "@mantine/core"
import { observer } from "mobx-react-lite"
import { FormModalStore } from "../../app/FormModalStore"
import * as Yup from "yup"
import { useForm, yupResolver } from "@mantine/form"
import { sendParticipationRequest } from "../../shared/api/mail"
import { AxiosError } from "axios"
import { IconAlertCircle, IconCircleCheck } from "@tabler/icons-react"
import { IMaskInput } from "react-imask"
import { useMediaQuery } from "@mantine/hooks"
import { PrivacyModalStore } from "../../app/PrivacyModalStore"

const useStyles = createStyles((theme) => ({
  modalContent: {
    width: "100%",
    maxWidth: 640,

    [theme.fn.smallerThan(1024)]: {
      maxWidth: 480,
    },
  },

  modalHeader: {
    padding: "32px 32px 24px",

    [theme.fn.smallerThan(425)]: {
      padding: 16,
    },
  },

  modalBody: {
    padding: 32,

    [theme.fn.smallerThan(425)]: {
      padding: 16,
    },
  },

  modalTitle: {
    fontWeight: 900,
    fontSize: 24,

    [theme.fn.smallerThan(425)]: {
      fontWeight: 700,
      fontSize: 20,
    },
  },

  checkbox: {
    ".mantine-InputWrapper-error": {
      fontSize: 16,

      [theme.fn.smallerThan(425)]: {
        fontSize: 14,
      },
    },
  },
}))

const schema = Yup.object().shape({
  name: Yup.string().required("Имя обязательно"),
  phone: Yup.string().required("Телефон обязателен").length(18, "Пожалуйста, укажите телефон полностью"),
  termsOfService: Yup.bool().oneOf([ true ], "Вы должны дать согласие на обработку данных"),
})

export const FormModal = observer(function FormModal(): React.JSX.Element {
    const { classes } = useStyles()
    const matched = useMediaQuery(`(max-width: 768px)`)
    const is425 = useMediaQuery(`(max-width: 425px)`)

    const [ isLoading, setIsLoading ] = useState<boolean>(false)
    const [ isSuccess, setIsSuccess ] = useState<boolean>(false)
    const [ error, setError ] = useState<string | null>(null)

    const form = useForm({
      validateInputOnChange: true,
      validate: yupResolver(schema),
      initialValues: {
        name: "",
        phone: "",
        termsOfService: true,
      },
    })

    const onSubmit = (values: typeof form.values): void => {
      if (!form.isDirty() || !form.isValid()) return
      const { termsOfService, ...rest } = values

      setIsLoading(true)
      setIsSuccess(false)
      setError(null)

      const body: Record<string, string> = { ...rest }

      if (FormModalStore.data.target) {
        body.target = FormModalStore.data.target
      }

      body.tabName = "Фитнес-тренер"

      void sendParticipationRequest(body)
        .then(() => {
          setIsSuccess(true)
          window.open("https://pthanks.bodycoach.pro/", "_blank")
        })
        .catch((err: AxiosError<{ message: string }>) => {
          if (err.response) {
            setError(err.response.data.message)
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

    useEffect(() => {
      if (!FormModalStore.isOpened) {
        form.reset()
        setIsLoading(false)
        setIsSuccess(false)
        setError(null)
      }
    }, [ FormModalStore.isOpened ])

    const close = () => FormModalStore.setIsOpened(false)
    const openPrivacy = (e: React.MouseEvent) => {
      e.preventDefault()
      PrivacyModalStore.setIsOpened(true)
    }

    return (
      <Modal.Root zIndex={2000} size="auto" centered opened={FormModalStore.isOpened} onClose={close}>
        <Modal.Overlay/>
        <Modal.Content radius={8} className={classes.modalContent}>
          <Modal.Header className={classes.modalHeader}>
            <Modal.Title className={classes.modalTitle}>Оставить заявку</Modal.Title>
            <Modal.CloseButton radius={4} size="lg"/>
          </Modal.Header>
          <Modal.Body className={classes.modalBody}>

            {isSuccess ? (
                <Container p={0} size={560}>
                  <Alert
                    styles={{ title: { fontSize: 18 }, message: { fontSize: 16 } }}
                    icon={<IconCircleCheck/>}
                    title="Заявка успешно отправлена!"
                    color="blue"
                    radius={4}
                  >
                    В ближайшее время с Вами свяжется наш сотрудник и ответит на все Вами вопросы.
                  </Alert>
                </Container>
              )
              : (
                <Container p={0} size={560}>
                  <form onSubmit={form.onSubmit(onSubmit)}>
                    <Stack spacing={is425 ? "md" : "xl"}>
                      <Text size="md" c="dimmed">Мы свяжемся с вами в ближайшее
                        время.</Text>
                      <TextInput
                        disabled={isLoading}
                        placeholder="Имя"
                        radius={4}
                        size={matched ? "md" : "lg"}
                        {...form.getInputProps("name")}
                      />
                      <Input.Wrapper id="participant-form-phone" error={form.errors["phone"]}
                                     size={matched ? "md" : "lg"}>
                        <Input
                          disabled={isLoading}
                          component={IMaskInput}
                          mask="+7 (000) 000-00-00"
                          id="participant-form-phone"
                          placeholder="+7 (918) 000-00-00"
                          radius={4}
                          size={matched ? "md" : "lg"}
                          {...form.getInputProps("phone")}
                        />
                      </Input.Wrapper>

                      <Checkbox
                        className={classes.checkbox}
                        {...form.getInputProps("termsOfService", { type: "checkbox" })}
                        color="blue"
                        radius={4}
                        size={matched ? "sm" : "md"}
                        label={
                          <>
                            Даю согласие на{" "}
                            <Anchor onClick={openPrivacy}>
                              обработку персональных данных
                            </Anchor>
                          </>
                        }
                      />

                      <Button
                        loading={isLoading}
                        type="submit"
                        fullWidth
                        variant="gradient"
                        gradient={{ from: "blue", to: "cyan" }}
                        radius={4}
                        size={matched ? "md" : "lg"}
                      >
                        Отправить
                      </Button>

                      {error && (
                        <Alert
                          icon={<IconAlertCircle/>}
                          title="Ошибка отправки данных"
                          color="red"
                          radius={4}
                        >
                          {error}
                        </Alert>
                      )}

                    </Stack>
                  </form>
                </Container>
              )}

          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    )
  },
)
